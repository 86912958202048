.navbar {
  position: relative;
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
  height: 60px;
  display: flex;
  justify-content: left;
  align-items:flex-start;
  font-family: "Lobster", cursive;
  font-size: 1.2rem;
}

.navbar-logo {
  color: #fff5ea;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 2px;
  list-style: none;
  text-align: left;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  display: flex;
  align-items: flex-start;
  height: 10%;
}

.nav-links {
  color: #fff5ea;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  color: #fff5ea;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff5ea;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 992px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 90vh; */
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #2f7976;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #42a9a6;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff5ea;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #42a9a6;
    text-decoration: none;
    color: #fff5ea;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff5ea;
    color: #42a9a6;
    transition: 250ms;
  }

  button {
    display: none;
  }
}
