.footer {
  height: 120px;
  background-color: #fff5ea;
  position: sticky;
  padding: 50px 30px;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.footer span {
  position: sticky;
  font-family: "Quicksand", sans-serif;
  color: #42a9a6;
}

@media only screen and (max-width: 575px) {
  .footer {
    height: 80px;
    padding: 10px;
  }

  .footer span {
    font-size: 10px;
  }
}
