* {
  box-sizing: border-box;
}

html,
body,
#root,
#root > div,
div[data-reactroot] {
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: #42a9a6;
}
