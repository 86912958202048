.wrapper {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  min-height: calc(100% - 180px);
  color: #fff5ea;
}

@media only screen and (max-width: 768px) {
  .wrapper {
    min-height: calc(100% - 160px);
  }
}

@media only screen and (max-width: 575px) {
  .wrapper {
    min-height: calc(100% - 140px);
  }
}
