.col-md-4 {
  position: relative;
  height: 100%;
}

.col-md-8 {
  position: relative;
  height: 100%;
}

.home {
  position: relative;
  left: 200px;
  font-family: "Lobster", cursive;
  font-size: 250px;
}

.about {
  position: relative;
  width: 120%;
  bottom: 80px;
  left: 200px;
  font-family: "Quicksand", sans-serif;
  text-align: center;
  font-size: 30px;
}

img {
  position: relative;
  bottom: 0;
  top: 0;
  height: 800px;
}

.portrait {
  left: 200px;
}

.skillPortrait {
  left: 350px;
}

.hedgehog {
  position: relative;
  bottom: 0;
  right: 0;
  height: 700px;
}

.list {
  position: relative;
  list-style-type: none;
  left: 300px;
  bottom: -50px;
  margin: 0;
  padding: 0;
  font-size: 50px;
  font-family: "Quicksand", sans-serif;
}

a:hover {
  text-decoration: none;
  color: #fff5ea;
}

a:link {
  text-decoration: none;
  color: #fff5ea;
}

a:visited {
  text-decoration: none;
  color: #fff5ea;
}

figure {
  position: relative;
  border: 5px solid;
  left: 250px;
  height: 200px;
  width: 650px;
  margin-bottom: 0px !important;
}

.project {
  position: relative;
  height: 100%;
  width: 100%;
}

.cap {
  position: relative;
  font-size: 35px;
  font-family: "Quicksand", sans-serif;
  left: 250px;
}

.description {
  font-size: 25px !important;
  margin-bottom: 0px;
}

.filler {
  font-size: 15px !important;
  margin-bottom: 10px !important;
}

@media only screen and (max-width: 1600px) {
  .col-md-6 {
    position: relative;
    height: 100%;
  }

  .home {
    position: relative;
    left: 125px;
    font-family: "Lobster", cursive;
    font-size: 200px;
  }

  .about {
    position: relative;
    width: 100%;
    left: 30px;
    bottom: 25px;
    font-family: "Quicksand", sans-serif;
    font-size: 23px;
    text-align: left;
  }

  img {
    position: relative;
    bottom: 0;
    top: 0;
    height: 565px;
  }

  .portrait {
    left: 100px;
  }

  .skillPortrait {
    left: 350px;
  }

  .contactPortrait {
    left: 100px;
  }

  .hedgehog {
    position: relative;
    bottom: 0;
    right: 0;
    height: 550px;
  }

  .list {
    position: relative;
    list-style-type: none;
    left: 250px;
    margin: 0;
    padding: 0;
    font-size: 30px;
    font-family: "Quicksand", sans-serif;
  }

  figure {
    position: relative;
    border: 5px solid;
    left: 100px;
    height: 150px;
    width: 487px;
    bottom: 25px;
  }

  .project {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .cap {
    position: relative;
    font-size: 30px;
    font-family: "Quicksand", sans-serif;
    left: 100px;
    bottom: 20px;
  }

  .description {
    font-size: 20px !important;
  }

  .filler {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 1024px) and (min-height: 600px) {
  .col-md-8 {
    width: 100% !important;
  }

  .col-md-4 {
    width: 100% !important;
  }

  .home {
    top: 25px;
    font-size: 150px;
    align-items: center;
  }

  .list {
    align-items: center;
    text-align: center;
    left: -50px;
    bottom: 0;
    top: 125px;
  }

  .listContact {
    top: 150px;
    left: -100px;
  }

  figure {
    left: 50px !important;
    bottom: 200px;
    width: 325px !important;
  }

  .project {
    left: 0px;
    top: 0px !important;
  }

  .cap {
    left: 50px !important;
    bottom: 50px;
  }

  img {
    right: 50px;
    top: 10px !important;
  }

  .portrait {
    height: 700px;
    align-items: center;
  }

  .contactPortrait {
    left: 350px;
  }

  .about {
    width: 100%;
    text-align: center;
    top: 25px;
    left: 25px;
  }

  .hedgehog {
    left: 200px;
  }

  .rowHome {
    display: flex;
    flex-direction: column;
  }

  .rowAbout {
    display: flex;
    flex-direction: column-reverse;
  }

  .contact {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1025px) and (min-height: 1026px) {
  .col-md-8 {
    width: 100% !important;
  }

  .col-md-4 {
    width: 100% !important;
  }

  .home {
    top: 0px;
    left: 200px;
    font-size: 200px !important;
    align-items: center;
  }

  .list {
    align-items: center;
    text-align: center;
    font-size: 45px !important;
    left: -175px !important;
    top: 200px !important;
  }

  .listContact {
    top: 150px;
    left: -100px;
  }

  figure {
    left: 50px !important;
    bottom: 300px;
    width: 400px !important;
  }

  .project {
    left: 0px;
    top: 0px !important;
  }

  .cap {
    left: 50px !important;
    bottom: 300px;
  }

  img {
    right: 50px;
    top: 10px !important;
  }

  .portrait {
    height: 850px !important;
    left: 200px;
    align-items: center;
  }

  .skillPortrait {
    left: 350px !important;
  }

  .contactPortrait {
    top: 0 !important;
  }

  .about {
    width: 100%;
    text-align: center;
    font-size: 25px !important;
    top: 75px !important;
    left: 25px;
  }

  .hedgehog {
    left: 200px;
    top: 75px !important;
    object-fit: cover;
    object-position: 0px 200px;
    height: 850px;
  }

  .rowHome {
    display: flex;
    flex-direction: column;
  }

  .rowAbout {
    display: flex;
    flex-direction: column-reverse;
  }

}

@media only screen and (max-width: 769px) {
  figure {
    bottom: 100px;
    left: 35px !important;
  }

  .cap {
    left: 35px !important;
    bottom: 100px;
  }
}

@media only screen and (max-width: 575.98px) {
  .home {
    top: 25px;
    font-size: 100px;
    left: 45px;
  }

  .list {
    left: 140px !important;
    text-align: center;
    top: -100px;
  }

  .listContact {
    top: -200px !important;
    left: 45px !important;
  }

  figure {
    left: 50px !important;
    top: 10px;
    width: 325px !important;
    margin: 0 0 1rem;
  }

  .project {
    left: 0px;
    top: 0px !important;
  }

  .cap {
    left: 0px !important;
    top: 10px;
    text-align: center;
    margin: 0px 20px;
  }

  .description {
    font-size: 18px !important;
  }

  .filler {
    font-size: 14px !important;
  }

  img {
    right: 50px;
    top: 10px !important;
  }

  .portrait {
    height: 600px;
    right: 0px;
    left: -30px;
  }

  .mobilePortrait {
    display: none;
  }

  .about {
    text-align: center;
    left: 0px !important;
    top: 10px;
    padding: 0px 10px;
  }

  .hedgehog {
    left: 0px;
    height: 600px;
  }

  .rowAbout {
    display: flex;
    flex-direction: column-reverse;
  }

  .contact {
    font-size: 23px;
  }
}

@media only screen and (max-width: 400px) {
  .home {
    left: 15px;
  }

  .list {
    left: 115px !important;
    top: -50px;
  }

  .listContact {
    left: 15px !important;
    top: -50px !important;
    padding-bottom: 120px !important;
  }

  figure {
    left: 20px !important;
  }

  .portrait {
    height: 525px;
    right: 0px;
    left: -30px;
  }

  .hedgehog {
    height: 500px;
  }
}
